<template>
  <div>
    <portal to="page-top-title">Domain #{{ id }}</portal>

    <box-single :url="`/domains/${id}`" :url-query="{ with: ['ip:id,ip'] }" data-prop="domain" loader resp-prop="data">
      <template slot="content">
        <row>
          <column-info title="Name">{{ domain.name }}</column-info>
          <column-info title="IP">{{ domain.ip.ip }}</column-info>
          <column-info title="Created At">{{ domain.created_at }}</column-info>
          <column-info title="Updated At">{{ domain.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="Status">
            <badge-status :value="domain.status_text"/>
          </column-info>
          <column-info title="Active">
            <badge-active :value="domain.active"/>
          </column-info>
          <column-info title="SSL Active">
            <badge-active :value="domain.ssl_active"/>
          </column-info>
          <column-info title="Used">
            <badge title="Count">{{ domain.used }}</badge>
          </column-info>
        </row>
      </template>
    </box-single>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Badge from '@/views/components/simple/Badge'
import BadgeStatus from '@/views/components/simple/BadgeStatus'
import domain from '@/modules/strubs/domain'

export default {
  name: 'DomainsSingle',
  metaInfo () {
    return { title: 'Domain #' + this.id }
  },
  components: {
    BoxSingle,
    BadgeActive,
    Badge,
    BadgeStatus
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  data () {
    return {
      domain: domain
    }
  }
}
</script>
